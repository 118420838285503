import { createApp } from "vue";
import MainView from "./MainView.vue";
import router from "./router";
import Notifications from "@kyvg/vue3-notification";
import VNetworkGraph from "v-network-graph";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "v-network-graph/lib/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const app = createApp(MainView);
app.use(router).use(VNetworkGraph).use(Notifications).mount("#app");
