<!-- FishTank 2024-->
<template lang="html">
  <nav
    class="navbar navbar-dark bg-dark navbar-expand-lg sticky-top"
    v-if="logged"
  >
    <div class="container-fluid">
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <span style="color: #ddd">Delete my account : </span>
            <input v-model="message" placeholder="DELETE" />
          </li>
          <li
            class="nav-item btn btn-outline-danger"
            v-if="userDelete"
            @click="deleteAccount()"
          >
            Confirm
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import firebase from "firebase/compat/app";
import { firebaseConfig } from "../firebase/index";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import router from "@/router";

const firbaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firbaseApp);

export default {
  name: "UserInfo",
  data() {
    return {
      message: "",
      userDelete: false,
      logged: false,
    };
  },
  watch: {
    userData: {
      handler() {
        this.userDataLoaded = true;
      },
      deep: true,
    },
    message: {
      handler() {
        if (this.message.toLowerCase() == "delete") {
          this.userDelete = true;
        } else {
          this.userDelete = false;
        }
      },
      deep: true,
    },
  },
  created() {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        this.logged = true;
      }
    });
  },
  methods: {
    deleteAccount() {
      this.logged = false;
      this.$notify({
        text: "User is deleted.",
        type: "info",
        fullWidth: true,
        duration: 3000,
      });
      firebaseAuth.currentUser
        .delete()
        .then(function () {
          router.go("/");
        })
        .catch(function () {
          router.go("/");
        });
      this.$forceUpdate();
    },
  },
};
</script>
