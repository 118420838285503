/* FishTank 2024 */
/* Common utils for filters and parsing components */

function to_array(data) {
  return data instanceof Array ? data : [data];
}
function parseNumber(x) {
  return isNaN(parseInt(x)) ? 0 : parseInt(x);
}

function clean_key(value) {
  if (
    typeof value === "string" &&
    value != "toString" &&
    value != "__prefix" &&
    value != "__text"
  ) {
    value = value.replaceAll("_", " ");
    value = `${value} :`;
    return value.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
  }
  return "";
}

function clean_object(obj) {
  Object.keys(obj).forEach((key) => {
    if (key.includes(":")) {
      obj[key.split(":")[1]] = obj[key];
      delete obj[key];
    }
  });
  return obj;
}

function has_key(x, y) {
  if (x instanceof Object) {
    return Object.prototype.hasOwnProperty.call(x, y);
  }
  return false;
}

function get_value(x) {
  if (x instanceof Object) {
    if (Object.prototype.hasOwnProperty.call(x, "__text")) {
      return x.__text;
    } else {
      return Object.values(x).join(", ");
    }
  }
  if (x instanceof Array) {
    let a = [];
    x.forEach((a) => {
      if (typeof a == "string") {
        return x.join(", ");
      }
      if (a instanceof Object) {
        a.push(get_value(x));
      }
      if (String(x) == "[object Object]") {
        a.push(get_value(x));
      }
    });
    if (x instanceof Object) {
      a.push(get_value(x));
    }
    if (String(x) == "[object Object]") {
      a.push(get_value(x));
    }
    return a.join(", ");
  }
  if (x instanceof Object) {
    return get_value(x);
  }
  if (String(x) == "[object Object]") {
    return get_value(x);
  }
  return x;
}

function framerate(x) {
  if (x) {
    if (isNaN(x)) {
      let e_r = x.split(" ");
      if (e_r[1] == "1") {
        return e_r[0];
      } else {
        return (parseInt(e_r[0]) / parseInt(e_r[1])).toFixed(2);
      }
    }
    return x;
  }
  return "?";
}

function yesno(x) {
  if (x) {
    return "YES";
  }
  return "NO";
}

function pictureformat(x) {
  if (x) {
    return "3D";
  }
  return "2D";
}

function contentkind(x) {
  if (typeof x === "undefined") {
    return "undefined";
  }
  if (typeof x === "string") {
    x = x.toUpperCase();
  } else {
    x = x.toString();
  }
  if (x == "advertisement") {
    x = "advert";
  }
  if (x == "transitional") {
    x = "transi";
  }
  return x.toUpperCase();
}

function aspect_ratio_decimal(content_type, ratio) {
  if (content_type == "SMPTE") {
    let r = "";
    if (ratio.includes("x")) {
      r = ratio.split("x");
    } else {
      r = ratio.split(" ");
    }
    return (parseInt(r[0]) / parseInt(r[1])).toFixed(2);
  }
  if (content_type == "IOP") {
    return ratio;
  }
}

function get_editrate(edit_rate) {
  edit_rate = get_value(edit_rate);
  let e_r = edit_rate.split(" ");
  if (e_r[1] == "1") {
    return parseInt(e_r[0]);
  } else {
    return (parseInt(e_r[0]) / parseInt(e_r[1])).toFixed(2);
  }
}

function get_timecode(frmcnt, fps) {
  if (fps == 0) {
    fps = 1;
  }
  if (!Number.isInteger(fps)) {
    fps = parseInt(fps) + 1;
  }
  let framecount = parseInt(frmcnt);
  let fd = framecount / fps;
  let f = framecount % fps;
  let sd = fd / 60;
  let s = Math.floor(fd % 60);
  let md = sd / 60;
  let m = Math.floor(sd % 60);
  let h = Math.floor(md % 60);
  if (f < 10) {
    f = "0" + f;
  }
  if (s < 10) {
    s = "0" + s;
  }
  if (m < 10) {
    m = "0" + m;
  }
  if (h < 10) {
    h = "0" + h;
  }
  return `${h}:${m}:${s}:${f}`;
}

function humantime(sec) {
  let second = parseInt(sec);
  let sd = second / 60;
  let s = Math.floor(second % 60);
  let md = sd / 60;
  let m = Math.floor(sd % 60);
  let h = Math.floor(md % 60);
  if (s < 10) {
    s = "0" + s;
  }
  if (m < 10) {
    m = "0" + m;
  }
  if (h < 10) {
    h = "0" + h;
  }
  return s;
}

function humansize(x) {
  let unit = ["B", "KB", "MB", "GB", "TB", "PB"];
  let i;
  for (i = 0; i < unit.length; i++) {
    if (Math.abs(x) < 1000.0) {
      return `${Math.floor(x)} ${unit[i]}`;
    } else {
      x = x / 1000.0;
    }
  }
}
function aspectratio(x) {
  x = get_value(x);
  let e_r = x.split("/");
  if (e_r[1] == "1") {
    return e_r[0];
  }
  let aspect = (parseInt(e_r[0]) / parseInt(e_r[1])).toFixed(2);
  if (aspect === 1.78) {
    return "16:9";
  }
  return aspect;
}

export {
  humantime,
  aspectratio,
  humansize,
  get_timecode,
  get_editrate,
  aspect_ratio_decimal,
  contentkind,
  pictureformat,
  yesno,
  has_key,
  clean_key,
  clean_object,
  framerate,
  get_value,
  to_array,
  parseNumber,
};
