<!-- FishTank 2024-->
<script setup>
import PrettyPrint from "./tools/PrettyPrint.vue";
</script>

<template lang="html">
  <nav class="navbar navbar-dark bg-dark navbar-expand-lg sticky-top">
    <div class="container-fluid">
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <input
              type="file"
              ref="fileupload"
              class="FileUpload"
              accept=".xml"
              @change="onChange($event)"
            />
            <div class="DropZone">
              <span v-if="filename == null">Open a Dolby Vision XML file</span>
              <span v-else>{{ filename }}</span>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav d-flex">
          <li class="nav-item">
            <span
              class="btn btn-outline-warning"
              v-if="filename == null && data != null"
            >
              This is a sample !
            </span>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-bs-toggle="modal"
              data-bs-target="#editModal"
              >Edit XML</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="reset()">Clear</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container-fluid bg">
    <!-- Edit XML Modal -->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Edit XML</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <textarea
              v-model="raw"
              class="form-control"
              rows="35"
              placeholder="Dolby Vision XML <DolbyLabsMDF>...</DolbyLabsMDF>"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- Shot Details Modal -->
    <div
      class="modal fade"
      id="shotDetailModal"
      tabindex="-1"
      aria-labelledby="shotDetailModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="shotDetailModalLabel">Shot detail</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <PrettyPrint
              v-bind:data="shotDetail"
              v-bind:indentation="0"
            ></PrettyPrint>
          </div>
        </div>
      </div>
    </div>
    <!-- Data -->
    <div class="row">
      <div class="col-5" v-if="data != null">
        <div class="side scroll">
          <h4>
            Dolby Vision DolbyLabsMDF
            <small v-if="data.DolbyLabsMDF.$.version !== undefined"
              >version {{ data.DolbyLabsMDF.$.version }}</small
            >
            <small v-else-if="data.DolbyLabsMDF.Version !== undefined"
              >version {{ data.DolbyLabsMDF.Version.join(" - ") }}</small
            >
          </h4>
          <h5>Main</h5>
          <table class="table table-sm">
            <tbody>
              <tr v-if="data.DolbyLabsMDF.SourceList !== undefined">
                <td>Source Unique ID</td>
                <td>
                  <template
                    v-for="(SourceList, sl) in data.DolbyLabsMDF.SourceList"
                    :key="sl"
                  >
                    <template
                      v-for="(Source, so) in SourceList.Source"
                      :key="so"
                    >
                      {{ Source.UniqueID.join(" - ") }}
                    </template>
                  </template>
                </td>
              </tr>
              <tr v-if="data.DolbyLabsMDF.RevisionHistory != null">
                <td>Revision History</td>
                <td>
                  <template
                    v-for="(RevisionHistory, rhs) in data.DolbyLabsMDF
                      .RevisionHistory"
                    :key="rhs"
                  >
                    {{ RevisionHistory.Revision.length }} Revisions<br />
                    Last Revision :<br />
                    <PrettyPrint
                      v-bind:data="
                        RevisionHistory.Revision[
                          RevisionHistory.Revision.length - 1
                        ]
                      "
                      v-bind:indentation="0"
                    ></PrettyPrint>
                  </template>
                </td>
              </tr>
              <tr v-if="data.DolbyLabsMDF.Outputs != null">
                <td>{{ data.DolbyLabsMDF.Outputs.length }} Outputs</td>
                <td>
                  <template
                    v-for="(Outputs, ops) in data.DolbyLabsMDF.Outputs"
                    :key="ops"
                  >
                    <template v-for="(Output, op) in Outputs.Output" :key="op">
                      <PrettyPrint
                        v-bind:data="Output"
                        v-bind:indentation="0"
                      ></PrettyPrint>
                    </template>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col" v-if="data != null">
        <div class="side scroll">
          <template
            v-for="(Outputs, ops) in data.DolbyLabsMDF.Outputs"
            :key="ops"
          >
            <template v-for="(Output, op) in Outputs.Output" :key="op">
              <template v-for="(Video, vd) in Output.Video" :key="vd">
                <template v-for="(Track, tc) in Video.Track" :key="tc">
                  <h5>
                    Shots <small>{{ Track.Shot.length }}</small>
                  </h5>
                  <div
                    class="container"
                    v-for="(Shot, sh) in Track.Shot"
                    :key="sh"
                  >
                    <div class="row">
                      <div class="col">
                        {{ sh + 1 }}
                        UniqueID : {{ Shot.UniqueID.join(" - ") }}<br />
                        <template
                          v-for="(Source, src) in Shot.Source"
                          :key="src"
                        >
                          Source ParentID : {{ Source.ParentID.join(" - ")
                          }}<br />
                          Source In : {{ Source.In.join(" - ") }}<br />
                        </template>
                        <template
                          v-for="(Record, src) in Shot.Record"
                          :key="src"
                        >
                          Record In : {{ Record.In.join(" - ") }}<br />
                          Record Duration : {{ Record.Duration.join(" - ")
                          }}<br />
                        </template>
                      </div>
                      <div class="col">
                        <a
                          class="btn btn-outline-dark"
                          data-bs-toggle="modal"
                          data-bs-target="#shotDetailModal"
                          @click="setShotDetail(Shot)"
                          >More</a
                        >
                      </div>
                      <hr />
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xml2js from "xml2js";

export default {
  name: "DolbyVision",
  data() {
    return {
      shotDetail: null,
      to_skip: ["Shot"],
      raw: `<?xml version="1.0" encoding="UTF-8"?>
<DolbyLabsMDF version="2.0.5" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
  <RevisionHistory>
    <Revision>
      <DateTime>2020-02-15T03:48:49Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-15T03:48:49Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-15T03:48:49Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:11:08Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:12:02Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:13:17Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:15:05Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:11:08Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:12:02Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:13:17Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:15:05Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:15:05Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:15:05Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:16:05Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:16:05Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:08:13Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:15:05Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T22:16:05Z</DateTime>
      <Author>Blackmagic Design</Author>
      <Software>DaVinci Resolve Studio</Software>
      <SoftwareVersion>16.1.3.004</SoftwareVersion>
      <Comment/>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T16:38:31-08:00</DateTime>
      <Author>Dolby Laboratories</Author>
      <Software>metafier</Software>
      <SoftwareVersion>5.0.0</SoftwareVersion>
      <Comment>Inserted 1440 black frames at Head of Metadata</Comment>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T16:38:31-08:00</DateTime>
      <Author>Dolby Laboratories</Author>
      <Software>metafier</Software>
      <SoftwareVersion>5.0.0</SoftwareVersion>
      <Comment>Inserted 48 black frames at Tail of Metadata</Comment>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T16:38:56-08:00</DateTime>
      <Author>Dolby Laboratories</Author>
      <Software>metafier</Software>
      <SoftwareVersion>5.0.0</SoftwareVersion>
      <Comment>Inserted 48 black frames at Tail of Metadata</Comment>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T16:39:07-08:00</DateTime>
      <Author>Dolby Laboratories</Author>
      <Software>metafier</Software>
      <SoftwareVersion>5.0.0</SoftwareVersion>
      <Comment>Inserted 48 black frames at Tail of Metadata</Comment>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T16:39:16-08:00</DateTime>
      <Author>Dolby Laboratories</Author>
      <Software>metafier</Software>
      <SoftwareVersion>5.0.0</SoftwareVersion>
      <Comment>Inserted 48 black frames at Tail of Metadata</Comment>
    </Revision>
    <Revision>
      <DateTime>2020-02-25T16:52:52-08:00</DateTime>
      <Author>Dolby Laboratories</Author>
      <Software>metafier</Software>
      <SoftwareVersion>5.0.0</SoftwareVersion>
      <Comment>Inserted 48 black frames at Tail of Metadata</Comment>
    </Revision>
    <Revision>
      <DateTime>2022-09-23T11:01:07-07:00</DateTime>
      <Author>Dolby Laboratories</Author>
      <Software>metafier</Software>
      <SoftwareVersion>5.2.0</SoftwareVersion>
      <Comment>Changed Metadata Frame Rate to 25/1</Comment>
    </Revision>
  </RevisionHistory>
  <SourceList>
    <Source>
      <UniqueID>7e1dcf94-7e1d-7e1d-7e1d-7e1dcf940ed2</UniqueID>
    </Source>
  </SourceList>
  <Outputs>
    <Output name="Domestic Digital Cinema">
      <UniqueID>dca0b88a-dca0-dca0-dca0-dca0b88adc52</UniqueID>
      <NumberVideoTracks>1</NumberVideoTracks>
      <NumberAudioTracks>0</NumberAudioTracks>
      <CanvasAspectRatio>1.77778</CanvasAspectRatio>
      <ImageAspectRatio>2.4</ImageAspectRatio>
      <Video>
        <Track name="Main Layer">
          <UniqueID>a09e2332-a09e-a09e-a09e-a09e2332db81</UniqueID>
          <Rate>
            <n>25</n>
            <d>1</d>
          </Rate>
          <ColorEncoding>
            <Primaries>
              <Red>0.68,0.32</Red>
              <Green>0.265,0.69</Green>
              <Blue>0.15,0.06</Blue>
            </Primaries>
            <WhitePoint>0.3127,0.329</WhitePoint>
            <MinimumBrightness>0</MinimumBrightness>
            <PeakBrightness>10000</PeakBrightness>
            <Encoding>pq</Encoding>
            <ColorSpace>rgb</ColorSpace>
            <SignalRange>computer</SignalRange>
            <BitDepth>16</BitDepth>
            <ChromaFormat>444</ChromaFormat>
          </ColorEncoding>
          <Level6>
            <MaxFALL>0</MaxFALL>
            <MaxCLL>0</MaxCLL>
          </Level6>
          <PluginNode>
            <DolbyEDR>
              <AlgorithmVersions>2,0</AlgorithmVersions>
              <Characteristics level="0">
                <MasteringDisplay id="7" name="4000-nit, P3, D65, ST.2084, Full">
                  <ID>7</ID>
                  <Name>4000-nit, P3, D65, ST.2084, Full</Name>
                  <Primaries>
                    <Red>0.68,0.32</Red>
                    <Green>0.265,0.69</Green>
                    <Blue>0.15,0.06</Blue>
                  </Primaries>
                  <WhitePoint>0.3127,0.329</WhitePoint>
                  <MinimumBrightness>0.005</MinimumBrightness>
                  <PeakBrightness>4000</PeakBrightness>
                  <Encoding>pq</Encoding>
                  <ColorSpace>rgb</ColorSpace>
                  <SignalRange>computer</SignalRange>
                  <DiagonalSize>42</DiagonalSize>
                  <BitDepth>16</BitDepth>
                  <ChromaFormat>444</ChromaFormat>
                </MasteringDisplay>
                <TargetDisplay id="1" name="100-nit, BT.709, BT.1886, Full">
                  <ID>1</ID>
                  <Name>100-nit, BT.709, BT.1886, Full</Name>
                  <Primaries>
                    <Red>0.64,0.33</Red>
                    <Green>0.3,0.6</Green>
                    <Blue>0.15,0.06</Blue>
                  </Primaries>
                  <WhitePoint>0.3127,0.329</WhitePoint>
                  <MinimumBrightness>0.005</MinimumBrightness>
                  <PeakBrightness>100</PeakBrightness>
                  <Encoding>gamma_bt1886</Encoding>
                  <ColorSpace>rgb</ColorSpace>
                  <SignalRange>computer</SignalRange>
                  <DiagonalSize>42</DiagonalSize>
                  <BitDepth>16</BitDepth>
                  <ChromaFormat>444</ChromaFormat>
                </TargetDisplay>
                <TargetDisplay id="48" name="1000-nit, P3, D65, ST.2084, Full">
                  <ID>48</ID>
                  <Name>1000-nit, P3, D65, ST.2084, Full</Name>
                  <Primaries>
                    <Red>0.68,0.32</Red>
                    <Green>0.265,0.69</Green>
                    <Blue>0.15,0.06</Blue>
                  </Primaries>
                  <WhitePoint>0.3127,0.329</WhitePoint>
                  <MinimumBrightness>0</MinimumBrightness>
                  <PeakBrightness>1000</PeakBrightness>
                  <Encoding>pq</Encoding>
                  <ColorSpace>rgb</ColorSpace>
                  <SignalRange>computer</SignalRange>
                  <DiagonalSize>42</DiagonalSize>
                  <BitDepth>16</BitDepth>
                  <ChromaFormat>444</ChromaFormat>
                </TargetDisplay>
              </Characteristics>
            </DolbyEDR>
          </PluginNode>
          <Shot>
            <UniqueID>0a8d630d-0a8d-0a8d-0a8d-0a8d630d5448</UniqueID>
            <Source>
              <ParentID>7e1dcf94-7e1d-7e1d-7e1d-7e1dcf940ed2</ParentID>
              <In>0</In>
            </Source>
            <Record>
              <In>0</In>
              <Duration>24</Duration>
            </Record>
            <PluginNode>
              <DolbyEDR level="1">
                <ImageCharacter>0,0,0</ImageCharacter>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>1</TID>
                <Trim>0,0,0,0,0,0,0,0,1</Trim>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>48</TID>
                <Trim>0,0,0,0,0,0,0,0,1</Trim>
              </DolbyEDR>
            </PluginNode>
          </Shot>
          <Shot>
            <UniqueID>48e153d9-48e1-48e1-48e1-48e153d9d435</UniqueID>
            <Source>
              <ParentID>7e1dcf94-7e1d-7e1d-7e1d-7e1dcf940ed2</ParentID>
              <In>0</In>
            </Source>
            <Record>
              <In>24</In>
              <Duration>318</Duration>
            </Record>
            <PluginNode>
              <DolbyEDR level="1">
                <ImageCharacter>0.00389105,0.243133,0.600977</ImageCharacter>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>1</TID>
                <Trim>0,0,0,0,-0.044,0.095,0,-0.106,-1</Trim>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>48</TID>
                <Trim>0,0,0,0,0.01,0,0,0,-1</Trim>
              </DolbyEDR>
            </PluginNode>
          </Shot>
          <Shot>
            <UniqueID>c883cd72-c883-c883-c883-c883cd726168</UniqueID>
            <Source>
              <ParentID>7e1dcf94-7e1d-7e1d-7e1d-7e1dcf940ed2</ParentID>
              <In>0</In>
            </Source>
            <Record>
              <In>342</In>
              <Duration>18</Duration>
            </Record>
            <PluginNode>
              <DolbyEDR level="1">
                <ImageCharacter>0.00389105,0.243133,0.600977</ImageCharacter>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>1</TID>
                <Trim>0,0,0,0,-0.044,0.095,0,-0.106,-1</Trim>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>48</TID>
                <Trim>0,0,0,0,0.01,0,0,0,-1</Trim>
              </DolbyEDR>
            </PluginNode>
          </Shot>
          <Shot>
            <UniqueID>316325a1-3163-3163-3163-316325a1f48c</UniqueID>
            <Source>
              <ParentID>7e1dcf94-7e1d-7e1d-7e1d-7e1dcf940ed2</ParentID>
              <In>0</In>
            </Source>
            <Record>
              <In>360</In>
              <Duration>18</Duration>
            </Record>
            <PluginNode>
              <DolbyEDR level="1">
                <ImageCharacter>0.00389105,0.243133,0.600977</ImageCharacter>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>1</TID>
                <Trim>0,0,0,0,-0.044,0.095,0,-0.106,-1</Trim>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>48</TID>
                <Trim>0,0,0,0,0.01,0,0,0,-1</Trim>
              </DolbyEDR>
            </PluginNode>
          </Shot>
          <Shot>
            <UniqueID>a63e9a7b-a63e-a63e-a63e-a63e9a7b1604</UniqueID>
            <Source>
              <ParentID>7e1dcf94-7e1d-7e1d-7e1d-7e1dcf940ed2</ParentID>
              <In>0</In>
            </Source>
            <Record>
              <In>378</In>
              <Duration>18</Duration>
            </Record>
            <PluginNode>
              <DolbyEDR level="1">
                <ImageCharacter>0.00389105,0.243133,0.600977</ImageCharacter>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>1</TID>
                <Trim>0,0,0,0,-0.044,0.095,0,-0.106,-1</Trim>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>48</TID>
                <Trim>0,0,0,0,0.01,0,0,0,-1</Trim>
              </DolbyEDR>
            </PluginNode>
          </Shot>
          <Shot>
            <UniqueID>09376fc0-0937-0937-0937-09376fc0d5b6</UniqueID>
            <Source>
              <ParentID>7e1dcf94-7e1d-7e1d-7e1d-7e1dcf940ed2</ParentID>
              <In>0</In>
            </Source>
            <Record>
              <In>396</In>
              <Duration>18</Duration>
            </Record>
            <PluginNode>
              <DolbyEDR level="1">
                <ImageCharacter>0.00389105,0.243133,0.600977</ImageCharacter>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>1</TID>
                <Trim>0,0,0,0,-0.044,0.095,0,-0.106,-1</Trim>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>48</TID>
                <Trim>0,0,0,0,0.01,0,0,0,-1</Trim>
              </DolbyEDR>
            </PluginNode>
          </Shot>
          <Shot>
            <UniqueID>2dd3f31e-2dd3-2dd3-2dd3-2dd3f31ed5b7</UniqueID>
            <Source>
              <ParentID>7e1dcf94-7e1d-7e1d-7e1d-7e1dcf940ed2</ParentID>
              <In>0</In>
            </Source>
            <Record>
              <In>414</In>
              <Duration>18</Duration>
            </Record>
            <PluginNode>
              <DolbyEDR level="1">
                <ImageCharacter>0.00389105,0.243133,0.600977</ImageCharacter>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>1</TID>
                <Trim>0,0,0,0,-0.044,0.095,0,-0.106,-1</Trim>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>48</TID>
                <Trim>0,0,0,0,0.01,0,0,0,-1</Trim>
              </DolbyEDR>
            </PluginNode>
          </Shot>
          <Shot>
            <UniqueID>3d2f7fac-3d2f-3d2f-3d2f-3d2f7face7bf</UniqueID>
            <Source>
              <ParentID>7e1dcf94-7e1d-7e1d-7e1d-7e1dcf940ed2</ParentID>
              <In>0</In>
            </Source>
            <Record>
              <In>432</In>
              <Duration>18</Duration>
            </Record>
            <PluginNode>
              <DolbyEDR level="1">
                <ImageCharacter>0.00389105,0.243133,0.600977</ImageCharacter>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>1</TID>
                <Trim>0,0,0,0,-0.044,0.095,0,-0.106,-1</Trim>
              </DolbyEDR>
              <DolbyEDR level="2">
                <TID>48</TID>
                <Trim>0,0,0,0,0.01,0,0,0,-1</Trim>
              </DolbyEDR>
            </PluginNode>
          </Shot>
        </Track>
      </Video>
    </Output>
  </Outputs>
</DolbyLabsMDF>`,
      data: null,
      filename: null,
    };
  },
  watch: {
    raw: {
      handler(raw) {
        if (raw != null) {
          this.data = null;
          if (raw != "") {
            this.parseXML(raw).then((data) => {
              if (Object.prototype.hasOwnProperty.call(data, "DolbyLabsMDF")) {
                this.data = data;
                console.log(this.data);
              } else {
                this.filename = null;
                this.$notify({
                  text: "XML is not DolbyLabsMDF.",
                  type: "error",
                  fullWidth: true,
                  duration: 3000,
                });
              }
            });
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.parseXML(this.raw).then((data) => {
      if (data == null) return;
      if (Object.prototype.hasOwnProperty.call(data, "DolbyLabsMDF")) {
        this.data = data;
      }
    });
  },
  methods: {
    setShotDetail(shot) {
      this.shotDetail = shot;
    },
    reset() {
      this.raw = null;
      this.data = null;
      this.filename = null;
      this.$refs.fileupload.value = null;
    },
    onChange(event) {
      const reader = new FileReader();
      if (event.target.files[0].name.includes(".xml")) {
        this.filename = event.target.files[0].name.substring(0, 100);
        this.$notify({
          text: "Loading xml",
          type: "info",
          fullWidth: true,
          duration: 3000,
        });
        reader.onload = (res) => {
          this.raw = res.target.result;
        };
        reader.onerror = (err) => console.log(err);
        reader.readAsText(event.target.files[0]);
      } else {
        this.$notify({
          text: "File is not a xml.",
          type: "error",
          fullWidth: true,
          duration: 3000,
        });
      }
    },
    parseXML(data) {
      return new Promise((resolve) => {
        let parser = new xml2js.Parser({
          trim: true,
          explicitArray: true,
        });
        parser.parseString(data, function (err, result) {
          resolve(result);
        });
      });
    },
  },
};
</script>
