<template lang="html">
  <div>
    {{ to_skip }}
    <div v-if="isObject(data)" v-bind:style="classObject(0)">
      <div v-for="(value, key) in data" :key="key">
        <template v-if="key != 'Shot'">
          <span v-if="!isObject(value)">
            <b>{{ cleanKey(key) }} </b>
            <span v-if="isArray(value)">
              <template v-for="(svalue, skey) in value" :key="skey">
                <span v-if="isObject(svalue)">
                  <PrettyPrint
                    v-bind:data="svalue"
                    v-bind:indentation="indent + 10"
                  >
                  </PrettyPrint>
                </span>
                <span v-else>
                  {{ getValue(svalue) }}
                </span>
              </template>
            </span>
            <span v-else>
              {{ getValue(value) }}
            </span>
          </span>
          <span v-else>
            <b>{{ cleanKey(key) }}</b>
            <PrettyPrint v-bind:data="value" v-bind:indentation="indent + 10">
            </PrettyPrint>
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/* FishTank 2024 */
/* Auto Pretty Print Object */
import { clean_key, get_value } from "./utils";

export default {
  name: "PrettyPrint",
  props: ["data", "indentation"],
  components: {
    PrettyPrint: require("./PrettyPrint.vue"),
  },
  data() {
    return {
      indent: 0,
    };
  },
  created: function () {
    this.indent = this.indentation + 5;
  },
  methods: {
    classObject: function (n) {
      return {
        "text-indent": `${this.indent + n}px`,
      };
    },
    isString: function (x) {
      if (x == "r0" || x == "r1" || x == "r2") {
        return false;
      }
      return (
        !this.isObject(x) &&
        !String(x).startsWith("function") &&
        !this.isArray(x)
      );
    },
    isArray: function (x) {
      return Array.isArray(x);
    },
    isObject: function (x) {
      return String(x) == "[object Object]";
    },
    getValue: function (x) {
      return get_value(x);
    },
    cleanKey: function (x) {
      if (x == "$") return "";
      return clean_key(x);
    },
  },
};
</script>
