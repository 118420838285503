const firebaseConfig = {
  apiKey: "AIzaSyCeD1HC37ft2OTqLUBcHNL9l1VVY4y_FmQ",
  authDomain: "divo-translator.firebaseapp.com",
  databaseURL: "https://divo-translator.firebaseio.com",
  projectId: "divo-translator",
  storageBucket: "divo-translator.appspot.com",
  messagingSenderId: "205970401151",
};

export { firebaseConfig };
