<!-- FishTank 2024-->
<template lang="html">
  <nav class="navbar navbar-dark bg-dark navbar-expand-lg sticky-top">
    <div class="container-fluid">
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <input
              type="file"
              ref="fileupload"
              class="FileUpload"
              accept=".xml"
              @change="onChange($event)"
            />
            <div class="DropZone">
              <span v-if="filename == null">Open a Dolby Atmos XML file</span>
              <span v-else>{{ filename }}</span>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav d-flex">
          <li class="nav-item">
            <span
              class="btn btn-outline-warning"
              v-if="filename == null && data != null"
            >
              This is a sample !
            </span>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-bs-toggle="modal"
              data-bs-target="#editModal"
              >Edit XML</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="reset()">Clear</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container-fluid bg">
    <!-- Modal -->
    <div
      class="modal fade"
      id="editModal"
      tabindex="-1"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Edit XML</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <textarea
              v-model="raw"
              class="form-control"
              rows="35"
              placeholder="Dolby Atmos XML <ebuCoreMain>...</ebuCoreMain>"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col" v-if="data != null">
        <div class="accordion side-empty" id="accordionData">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingHead">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseHead"
                aria-expanded="false"
                aria-controls="collapseHead"
              >
                <h4>Dolby Atmos</h4>
              </button>
            </h2>
            <div
              id="collapseHead"
              class="accordion-collapse collapse"
              aria-labelledby="headingHead"
              data-bs-parent="#accordionData"
            >
              <div class="accordion-body">
                <template v-for="(value, k) in data.ebuCoreMain.$" :key="k">
                  <span>
                    <b>{{ k }}</b> : {{ value }}<br />
                  </span>
                </template>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingAPR">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseAPR"
                aria-expanded="false"
                aria-controls="collapseAPR"
              >
                <div class="chip"><div class="chip_apr"></div></div>
                Audio Programme
              </button>
            </h2>
            <div
              id="collapseAPR"
              class="accordion-collapse collapse"
              aria-labelledby="headingAPR"
              data-bs-parent="#accordionData"
            >
              <div class="accordion-body">
                <template
                  v-for="(coreMetadata, c) in data.ebuCoreMain.coreMetadata"
                  :key="c"
                >
                  <template v-for="(format, f) in coreMetadata.format" :key="f">
                    <ul
                      class="list-group"
                      v-for="(
                        audioFormatExtended, k
                      ) in format.audioFormatExtended"
                      :key="k"
                    >
                      <li
                        class="list-group-item"
                        v-for="(
                          audioProgramme, ap
                        ) in audioFormatExtended.audioProgramme"
                        :key="ap"
                      >
                        Audio Programme ID :
                        {{ audioProgramme.$.audioProgrammeID }}<br />
                        Audio Programme Name :
                        {{ audioProgramme.$.audioProgrammeName }}<br />
                        Start : {{ audioProgramme.$.start }} - End :
                        {{ audioProgramme.$.end }}<br />
                        Linked to Audio Content
                      </li>
                    </ul>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingAC">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseAC"
                aria-expanded="false"
                aria-controls="collapseAC"
              >
                <div class="chip"><div class="chip_aco"></div></div>
                Audio Content
              </button>
            </h2>
            <div
              id="collapseAC"
              class="accordion-collapse collapse"
              aria-labelledby="headingAC"
              data-bs-parent="#accordionData"
            >
              <div class="accordion-body">
                <template
                  v-for="(coreMetadata, c) in data.ebuCoreMain.coreMetadata"
                  :key="c"
                >
                  <template v-for="(format, f) in coreMetadata.format" :key="f">
                    <ul
                      class="list-group"
                      v-for="(
                        audioFormatExtended, k
                      ) in format.audioFormatExtended"
                      :key="k"
                    >
                      <li
                        class="list-group-item"
                        v-for="(
                          audioContent, ac
                        ) in audioFormatExtended.audioContent"
                        :key="ac"
                      >
                        Audio Content ID : {{ audioContent.$.audioContentID
                        }}<br />
                        Audio Content Name :
                        {{ audioContent.$.audioContentName }}<br />
                        Linked to Audio Object
                      </li>
                    </ul>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingAO">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseAO"
                aria-expanded="false"
                aria-controls="collapseAO"
              >
                <div class="chip"><div class="chip_ao"></div></div>
                Audio Object
              </button>
            </h2>
            <div
              id="collapseAO"
              class="accordion-collapse collapse"
              aria-labelledby="headingAO"
              data-bs-parent="#accordionData"
            >
              <div class="accordion-body">
                <template
                  v-for="(coreMetadata, c) in data.ebuCoreMain.coreMetadata"
                  :key="c"
                >
                  <template v-for="(format, f) in coreMetadata.format" :key="f">
                    <ul
                      class="list-group"
                      v-for="(
                        audioFormatExtended, k
                      ) in format.audioFormatExtended"
                      :key="k"
                    >
                      <li
                        class="list-group-item"
                        v-for="(
                          audioObject, ao
                        ) in audioFormatExtended.audioObject"
                        :key="ao"
                      >
                        Audio Object ID : {{ audioObject.$.audioObjectID
                        }}<br />
                        Audio Object Name : {{ audioObject.$.audioObjectName
                        }}<br />
                        Start : {{ audioObject.$.start }} - Duration :
                        {{ audioObject.$.duration }}<br />
                        Linked to Audio Pack Format<br />
                        Linked to Audio Track UID
                      </li>
                    </ul>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingAP">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseAP"
                aria-expanded="false"
                aria-controls="collapseAP"
              >
                <div class="chip"><div class="chip_ap"></div></div>
                Audio Pack Format
              </button>
            </h2>
            <div
              id="collapseAP"
              class="accordion-collapse collapse"
              aria-labelledby="headingAP"
              data-bs-parent="#accordionData"
            >
              <div class="accordion-body">
                <template
                  v-for="(coreMetadata, c) in data.ebuCoreMain.coreMetadata"
                  :key="c"
                >
                  <template v-for="(format, f) in coreMetadata.format" :key="f">
                    <ul
                      class="list-group"
                      v-for="(
                        audioFormatExtended, k
                      ) in format.audioFormatExtended"
                      :key="k"
                    >
                      <li
                        class="list-group-item"
                        v-for="(
                          audioPackFormat, ap
                        ) in audioFormatExtended.audioPackFormat"
                        :key="ap"
                      >
                        Audio Pack Format ID :
                        {{ audioPackFormat.$.audioPackFormatID }}<br />
                        Audio Pack Format Name :
                        {{ audioPackFormat.$.audioPackFormatName }}<br />
                        Type Definition : {{ audioPackFormat.$.typeDefinition
                        }}<br />
                        Type Label : {{ audioPackFormat.$.typeLabel }}<br />
                        Linked to Audio Channel Format
                      </li>
                    </ul>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingATU">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseATU"
                aria-expanded="false"
                aria-controls="collapseATU"
              >
                <div class="chip"><div class="chip_atu"></div></div>
                Audio Track UID
              </button>
            </h2>
            <div
              id="collapseATU"
              class="accordion-collapse collapse"
              aria-labelledby="headingATU"
              data-bs-parent="#accordionData"
            >
              <div class="accordion-body">
                <template
                  v-for="(coreMetadata, c) in data.ebuCoreMain.coreMetadata"
                  :key="c"
                >
                  <template v-for="(format, f) in coreMetadata.format" :key="f">
                    <ul
                      class="list-group"
                      v-for="(
                        audioFormatExtended, k
                      ) in format.audioFormatExtended"
                      :key="k"
                    >
                      <li
                        class="list-group-item"
                        v-for="(
                          audioTrackUID, atu
                        ) in audioFormatExtended.audioTrackUID"
                        :key="atu"
                      >
                        UID : {{ audioTrackUID.$.UID }}<br />
                        Bit Depth : {{ audioTrackUID.$.bitDepth }}<br />
                        Sample Rate {{ audioTrackUID.$.sampleRate }}<br />
                        Linked to Audio Track Format<br />
                        Linked to Audio Pack Format
                      </li>
                    </ul>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingAT">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseAT"
                aria-expanded="false"
                aria-controls="collapseAT"
              >
                <div class="chip"><div class="chip_at"></div></div>
                Audio Track Format
              </button>
            </h2>
            <div
              id="collapseAT"
              class="accordion-collapse collapse"
              aria-labelledby="headingAT"
              data-bs-parent="#accordionData"
            >
              <div class="accordion-body">
                <template
                  v-for="(coreMetadata, c) in data.ebuCoreMain.coreMetadata"
                  :key="c"
                >
                  <template v-for="(format, f) in coreMetadata.format" :key="f">
                    <ul
                      class="list-group"
                      v-for="(
                        audioFormatExtended, k
                      ) in format.audioFormatExtended"
                      :key="k"
                    >
                      <li
                        class="list-group-item"
                        v-for="(
                          audioTrackFormat, atf
                        ) in audioFormatExtended.audioTrackFormat"
                        :key="atf"
                      >
                        Audio Track Format ID :
                        {{ audioTrackFormat.$.audioTrackFormatID }}<br />
                        Audio Track Format Name :
                        {{ audioTrackFormat.$.audioTrackFormatName }}<br />
                        Linked to Audio Stream Format<br />
                      </li>
                    </ul>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingAS">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseAS"
                aria-expanded="false"
                aria-controls="collapseAS"
              >
                <div class="chip"><div class="chip_as"></div></div>
                Audio Stream Format
              </button>
            </h2>
            <div
              id="collapseAS"
              class="accordion-collapse collapse"
              aria-labelledby="headingAS"
              data-bs-parent="#accordionData"
            >
              <div class="accordion-body">
                <template
                  v-for="(coreMetadata, c) in data.ebuCoreMain.coreMetadata"
                  :key="c"
                >
                  <template v-for="(format, f) in coreMetadata.format" :key="f">
                    <ul
                      class="list-group"
                      v-for="(
                        audioFormatExtended, k
                      ) in format.audioFormatExtended"
                      :key="k"
                    >
                      <li
                        class="list-group-item"
                        v-for="(
                          audioStreamFormat, asf
                        ) in audioFormatExtended.audioStreamFormat"
                        :key="asf"
                      >
                        Audio Stream Format ID :
                        {{ audioStreamFormat.$.audioStreamFormatID }}<br />
                        Audio Stream Format Name :
                        {{ audioStreamFormat.$.audioStreamFormatName }}<br />
                        Linked to Audio Channel Format
                      </li>
                    </ul>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <div class="chip"><div class="chip_ac"></div></div>
                Audio Channel Format
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionData"
            >
              <div class="accordion-body">
                <template
                  v-for="(coreMetadata, c) in data.ebuCoreMain.coreMetadata"
                  :key="c"
                >
                  <template v-for="(format, f) in coreMetadata.format" :key="f">
                    <ul
                      class="list-group"
                      v-for="(
                        audioFormatExtended, k
                      ) in format.audioFormatExtended"
                      :key="k"
                    >
                      <li
                        class="list-group-item"
                        v-for="(
                          audioChannelFormat, acf
                        ) in audioFormatExtended.audioChannelFormat"
                        :key="acf"
                      >
                        Audio Channel Format ID :
                        {{ audioChannelFormat.$.audioChannelFormatID }}<br />
                        Audio Channel Format Name :
                        {{ audioChannelFormat.$.audioChannelFormatName }}<br />
                      </li>
                    </ul>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-8" id="graph" v-if="data != null">
        <div class="graphZone side-empty">
          <v-network-graph
            :nodes="nodes"
            :edges="edges"
            v-model:layouts="layouts"
            :configs="configs"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xml2js from "xml2js";
import { ref } from "vue";
import * as vNG from "v-network-graph";
import { ForceLayout } from "v-network-graph/lib/force-layout";

export default {
  name: "DolbyAtmos",
  data() {
    return {
      raw: `<?xml version="1.0" encoding="UTF-8"?>
<ebuCoreMain
	xmlns="urn:ebu:metadata-schema:ebuCore_2016"
	xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="urn:ebu:metadata-schema:ebuCore_2016 ebucore.xsd" xml:lang="en">
	<coreMetadata>
		<format>
			<audioFormatExtended>
				<audioProgramme audioProgrammeID="APR_1001" audioProgrammeName="Atmos_Master" start="10:00:00.00000" end="10:00:07.71998">
					<audioContentIDRef>ACO_1001</audioContentIDRef>
				</audioProgramme>
				<audioContent audioContentID="ACO_1001" audioContentName="Atmos_Master_Content">
					<audioObjectIDRef>AO_1001</audioObjectIDRef>
					<dialogue mixedContentKind="0">2</dialogue>
				</audioContent>
				<audioObject audioObjectID="AO_1001" audioObjectName="Atmos_Bed_1" start="00:00:00.00000" duration="00:00:07.71998">
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
					<audioTrackUIDRef>ATU_00000001</audioTrackUIDRef>
					<audioTrackUIDRef>ATU_00000002</audioTrackUIDRef>
					<audioTrackUIDRef>ATU_00000003</audioTrackUIDRef>
					<audioTrackUIDRef>ATU_00000004</audioTrackUIDRef>
					<audioTrackUIDRef>ATU_00000005</audioTrackUIDRef>
					<audioTrackUIDRef>ATU_00000006</audioTrackUIDRef>
				</audioObject>
				<audioPackFormat audioPackFormatID="AP_00011001" audioPackFormatName="AtmosCustomPackFormat1" typeDefinition="DirectSpeakers" typeLabel="0001">
					<audioChannelFormatIDRef>AC_00011001</audioChannelFormatIDRef>
					<audioChannelFormatIDRef>AC_00011002</audioChannelFormatIDRef>
					<audioChannelFormatIDRef>AC_00011003</audioChannelFormatIDRef>
					<audioChannelFormatIDRef>AC_00011004</audioChannelFormatIDRef>
					<audioChannelFormatIDRef>AC_00011005</audioChannelFormatIDRef>
					<audioChannelFormatIDRef>AC_00011006</audioChannelFormatIDRef>
				</audioPackFormat>
				<audioChannelFormat audioChannelFormatID="AC_00011001" audioChannelFormatName="RoomCentricLeft" typeDefinition="DirectSpeakers" typeLabel="0001">
					<audioBlockFormat audioBlockFormatID="AB_00011001_00000001">
						<speakerLabel>RC_L</speakerLabel>
						<cartesian>1</cartesian>
						<position coordinate="X">-1.0000000000</position>
						<position coordinate="Y">1.0000000000</position>
						<position coordinate="Z">0.0000000000</position>
					</audioBlockFormat>
				</audioChannelFormat>
				<audioChannelFormat audioChannelFormatID="AC_00011002" audioChannelFormatName="RoomCentricRight" typeDefinition="DirectSpeakers" typeLabel="0001">
					<audioBlockFormat audioBlockFormatID="AB_00011002_00000001">
						<speakerLabel>RC_R</speakerLabel>
						<cartesian>1</cartesian>
						<position coordinate="X">1.0000000000</position>
						<position coordinate="Y">1.0000000000</position>
						<position coordinate="Z">0.0000000000</position>
					</audioBlockFormat>
				</audioChannelFormat>
				<audioChannelFormat audioChannelFormatID="AC_00011003" audioChannelFormatName="RoomCentricCenter" typeDefinition="DirectSpeakers" typeLabel="0001">
					<audioBlockFormat audioBlockFormatID="AB_00011003_00000001">
						<speakerLabel>RC_C</speakerLabel>
						<cartesian>1</cartesian>
						<position coordinate="X">0.0000000000</position>
						<position coordinate="Y">1.0000000000</position>
						<position coordinate="Z">0.0000000000</position>
					</audioBlockFormat>
				</audioChannelFormat>
				<audioChannelFormat audioChannelFormatID="AC_00011004" audioChannelFormatName="RoomCentricLFE" typeDefinition="DirectSpeakers" typeLabel="0001">
					<audioBlockFormat audioBlockFormatID="AB_00011004_00000001">
						<speakerLabel>RC_LFE</speakerLabel>
						<cartesian>1</cartesian>
						<position coordinate="X">-1.0000000000</position>
						<position coordinate="Y">1.0000000000</position>
						<position coordinate="Z">-1.0000000000</position>
					</audioBlockFormat>
				</audioChannelFormat>
				<audioChannelFormat audioChannelFormatID="AC_00011005" audioChannelFormatName="RoomCentricLeftSurround" typeDefinition="DirectSpeakers" typeLabel="0001">
					<audioBlockFormat audioBlockFormatID="AB_00011005_00000001">
						<speakerLabel>RC_Ls</speakerLabel>
						<cartesian>1</cartesian>
						<position coordinate="X">-1.0000000000</position>
						<position coordinate="Y">-0.3639700000</position>
						<position coordinate="Z">0.0000000000</position>
					</audioBlockFormat>
				</audioChannelFormat>
				<audioChannelFormat audioChannelFormatID="AC_00011006" audioChannelFormatName="RoomCentricRightSurround" typeDefinition="DirectSpeakers" typeLabel="0001">
					<audioBlockFormat audioBlockFormatID="AB_00011006_00000001">
						<speakerLabel>RC_Rs</speakerLabel>
						<cartesian>1</cartesian>
						<position coordinate="X">1.0000000000</position>
						<position coordinate="Y">-0.3639700000</position>
						<position coordinate="Z">0.0000000000</position>
					</audioBlockFormat>
				</audioChannelFormat>
				<audioStreamFormat audioStreamFormatID="AS_00011001" audioStreamFormatName="PCM_RoomCentricLeft" formatDefinition="PCM" formatLabel="0001">
					<audioChannelFormatIDRef>AC_00011001</audioChannelFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
					<audioTrackFormatIDRef>AT_00011001_01</audioTrackFormatIDRef>
				</audioStreamFormat>
				<audioStreamFormat audioStreamFormatID="AS_00011002" audioStreamFormatName="PCM_RoomCentricRight" formatDefinition="PCM" formatLabel="0001">
					<audioChannelFormatIDRef>AC_00011002</audioChannelFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
					<audioTrackFormatIDRef>AT_00011002_01</audioTrackFormatIDRef>
				</audioStreamFormat>
				<audioStreamFormat audioStreamFormatID="AS_00011003" audioStreamFormatName="PCM_RoomCentricCenter" formatDefinition="PCM" formatLabel="0001">
					<audioChannelFormatIDRef>AC_00011003</audioChannelFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
					<audioTrackFormatIDRef>AT_00011003_01</audioTrackFormatIDRef>
				</audioStreamFormat>
				<audioStreamFormat audioStreamFormatID="AS_00011004" audioStreamFormatName="PCM_RoomCentricLFE" formatDefinition="PCM" formatLabel="0001">
					<audioChannelFormatIDRef>AC_00011004</audioChannelFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
					<audioTrackFormatIDRef>AT_00011004_01</audioTrackFormatIDRef>
				</audioStreamFormat>
				<audioStreamFormat audioStreamFormatID="AS_00011005" audioStreamFormatName="PCM_RoomCentricLeftSurround" formatDefinition="PCM" formatLabel="0001">
					<audioChannelFormatIDRef>AC_00011005</audioChannelFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
					<audioTrackFormatIDRef>AT_00011005_01</audioTrackFormatIDRef>
				</audioStreamFormat>
				<audioStreamFormat audioStreamFormatID="AS_00011006" audioStreamFormatName="PCM_RoomCentricRightSurround" formatDefinition="PCM" formatLabel="0001">
					<audioChannelFormatIDRef>AC_00011006</audioChannelFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
					<audioTrackFormatIDRef>AT_00011006_01</audioTrackFormatIDRef>
				</audioStreamFormat>
				<audioTrackFormat audioTrackFormatID="AT_00011001_01" audioTrackFormatName="PCM_RoomCentricLeft" formatDefinition="PCM" formatLabel="0001">
					<audioStreamFormatIDRef>AS_00011001</audioStreamFormatIDRef>
				</audioTrackFormat>
				<audioTrackFormat audioTrackFormatID="AT_00011002_01" audioTrackFormatName="PCM_RoomCentricRight" formatDefinition="PCM" formatLabel="0001">
					<audioStreamFormatIDRef>AS_00011002</audioStreamFormatIDRef>
				</audioTrackFormat>
				<audioTrackFormat audioTrackFormatID="AT_00011003_01" audioTrackFormatName="PCM_RoomCentricCenter" formatDefinition="PCM" formatLabel="0001">
					<audioStreamFormatIDRef>AS_00011003</audioStreamFormatIDRef>
				</audioTrackFormat>
				<audioTrackFormat audioTrackFormatID="AT_00011004_01" audioTrackFormatName="PCM_RoomCentricLFE" formatDefinition="PCM" formatLabel="0001">
					<audioStreamFormatIDRef>AS_00011004</audioStreamFormatIDRef>
				</audioTrackFormat>
				<audioTrackFormat audioTrackFormatID="AT_00011005_01" audioTrackFormatName="PCM_RoomCentricLeftSurround" formatDefinition="PCM" formatLabel="0001">
					<audioStreamFormatIDRef>AS_00011005</audioStreamFormatIDRef>
				</audioTrackFormat>
				<audioTrackFormat audioTrackFormatID="AT_00011006_01" audioTrackFormatName="PCM_RoomCentricRightSurround" formatDefinition="PCM" formatLabel="0001">
					<audioStreamFormatIDRef>AS_00011006</audioStreamFormatIDRef>
				</audioTrackFormat>
				<audioTrackUID UID="ATU_00000001" bitDepth="24" sampleRate="48000">
					<audioTrackFormatIDRef>AT_00011001_01</audioTrackFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
				</audioTrackUID>
				<audioTrackUID UID="ATU_00000002" bitDepth="24" sampleRate="48000">
					<audioTrackFormatIDRef>AT_00011002_01</audioTrackFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
				</audioTrackUID>
				<audioTrackUID UID="ATU_00000003" bitDepth="24" sampleRate="48000">
					<audioTrackFormatIDRef>AT_00011003_01</audioTrackFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
				</audioTrackUID>
				<audioTrackUID UID="ATU_00000004" bitDepth="24" sampleRate="48000">
					<audioTrackFormatIDRef>AT_00011004_01</audioTrackFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
				</audioTrackUID>
				<audioTrackUID UID="ATU_00000005" bitDepth="24" sampleRate="48000">
					<audioTrackFormatIDRef>AT_00011005_01</audioTrackFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
				</audioTrackUID>
				<audioTrackUID UID="ATU_00000006" bitDepth="24" sampleRate="48000">
					<audioTrackFormatIDRef>AT_00011006_01</audioTrackFormatIDRef>
					<audioPackFormatIDRef>AP_00011001</audioPackFormatIDRef>
				</audioTrackUID>
			</audioFormatExtended>
		</format>
	</coreMetadata>
</ebuCoreMain>
      `,
      data: null,
      filename: null,
      configs: vNG.defineConfigs({
        view: {
          layoutHandler: new ForceLayout({
            positionFixedByDrag: false,
            positionFixedByClickWithAltKey: true,
            createSimulation: (d3, nodes, edges) => {
              // d3-force parameters
              const forceLink = d3.forceLink(edges).id((d) => d.id);
              return d3
                .forceSimulation(nodes)
                .force("edge", forceLink.distance(5).strength(0.5))
                .force("charge", d3.forceManyBody().strength(-1000))
                .alphaMin(0.001);
            },
          }),
          scalingObjects: true,
          minZoomLevel: 0.05,
          maxZoomLevel: 20,
        },
        node: {
          normal: {
            color: (n) =>
              n.name.startsWith("APR")
                ? "#ff0000"
                : n.name.startsWith("ACO")
                ? "#00ff00"
                : n.name.startsWith("AO")
                ? "#0000ff"
                : n.name.startsWith("AP")
                ? "#f79400"
                : n.name.startsWith("ATU")
                ? "#a02bc4"
                : n.name.startsWith("AT")
                ? "#ffe500"
                : n.name.startsWith("AS")
                ? "#515151"
                : "#dddddd",
          },
          label: {
            visible: true,
            fontFamily: undefined,
            fontSize: 11,
            lineHeight: 1.1,
            color: "#000000",
            margin: 4,
            direction: "south",
            text: "type",
          },
        },
        edge: {
          normal: {
            color: "#aaa",
            width: 3,
          },
          margin: 4,
          marker: {
            target: {
              type: "arrow",
              width: 4,
              height: 4,
            },
          },
        },
      }),
      nodes: {},
      edges: {},
      layouts: ref({
        nodes: {},
      }),
    };
  },
  watch: {
    raw: {
      handler(raw) {
        if (raw != null) {
          this.data = null;
          this.nodes = {};
          this.edges = {};
          if (raw != "") {
            this.parseXML(raw).then((data) => {
              if (Object.prototype.hasOwnProperty.call(data, "ebuCoreMain")) {
                this.data = data;
              } else {
                this.filename = null;
                this.$notify({
                  text: "XML is not ebuCoreMain.",
                  type: "error",
                  fullWidth: true,
                  duration: 3000,
                });
              }
            });
          }
        }
      },
      deep: true,
    },
    data: {
      handler(data) {
        if (data != null) {
          this.nodes = {};
          this.edges = {};
          if (
            Object.prototype.hasOwnProperty.call(
              data.ebuCoreMain,
              "coreMetadata"
            )
          ) {
            data.ebuCoreMain.coreMetadata.forEach((coreMetadata) => {
              if (
                Object.prototype.hasOwnProperty.call(coreMetadata, "format")
              ) {
                coreMetadata.format.forEach((format) => {
                  if (
                    Object.prototype.hasOwnProperty.call(
                      format,
                      "audioFormatExtended"
                    )
                  ) {
                    format.audioFormatExtended.forEach(
                      (audioFormatExtended) => {
                        if (
                          Object.prototype.hasOwnProperty.call(
                            audioFormatExtended,
                            "audioProgramme"
                          )
                        ) {
                          audioFormatExtended.audioProgramme.forEach(
                            (audioProgramme) => {
                              this.nodes[audioProgramme.$.audioProgrammeID] = {
                                name: audioProgramme.$.audioProgrammeID,
                                type: audioProgramme.$.audioProgrammeName,
                              };
                              audioProgramme.audioContentIDRef.forEach((id) => {
                                this.edges[
                                  `e${Object.keys(this.edges).length}`
                                ] = {
                                  source: audioProgramme.$.audioProgrammeID,
                                  target: id,
                                };
                              });
                            }
                          );
                        }
                        if (
                          Object.prototype.hasOwnProperty.call(
                            audioFormatExtended,
                            "audioContent"
                          )
                        ) {
                          audioFormatExtended.audioContent.forEach(
                            (audioContent) => {
                              this.nodes[audioContent.$.audioContentID] = {
                                name: audioContent.$.audioContentID,
                                type: audioContent.$.audioContentName,
                              };
                              audioContent.audioObjectIDRef.forEach((id) => {
                                this.edges[
                                  `e${Object.keys(this.edges).length}`
                                ] = {
                                  source: audioContent.$.audioContentID,
                                  target: id,
                                };
                              });
                            }
                          );
                        }
                        if (
                          Object.prototype.hasOwnProperty.call(
                            audioFormatExtended,
                            "audioObject"
                          )
                        ) {
                          audioFormatExtended.audioObject.forEach(
                            (audioObject) => {
                              this.nodes[audioObject.$.audioObjectID] = {
                                name: audioObject.$.audioObjectID,
                                type: audioObject.$.audioObjectName,
                              };
                              audioObject.audioTrackUIDRef.forEach((id) => {
                                this.edges[
                                  `e${Object.keys(this.edges).length}`
                                ] = {
                                  source: audioObject.$.audioObjectID,
                                  target: id,
                                };
                              });
                              audioObject.audioPackFormatIDRef.forEach((id) => {
                                this.edges[
                                  `e${Object.keys(this.edges).length}`
                                ] = {
                                  source: audioObject.$.audioObjectID,
                                  target: id,
                                };
                              });
                            }
                          );
                        }
                        if (
                          Object.prototype.hasOwnProperty.call(
                            audioFormatExtended,
                            "audioPackFormat"
                          )
                        ) {
                          audioFormatExtended.audioPackFormat.forEach(
                            (audioPackFormat) => {
                              this.nodes[audioPackFormat.$.audioPackFormatID] =
                                {
                                  name: audioPackFormat.$.audioPackFormatID,
                                  type: audioPackFormat.$.audioPackFormatName,
                                };
                              // audioPackFormat.audioChannelFormatIDRef.forEach(id => {
                              //       this.edges[`e${Object.keys(this.edges).length}`] = { source: audioPackFormat.$.audioPackFormatID, target: id }
                              // })
                            }
                          );
                        }
                        if (
                          Object.prototype.hasOwnProperty.call(
                            audioFormatExtended,
                            "audioTrackUID"
                          )
                        ) {
                          audioFormatExtended.audioTrackUID.forEach(
                            (audioTrackUID) => {
                              this.nodes[audioTrackUID.$.UID] = {
                                name: audioTrackUID.$.UID,
                                type: audioTrackUID.$.UID,
                              };
                              audioTrackUID.audioTrackFormatIDRef.forEach(
                                (id) => {
                                  this.edges[
                                    `e${Object.keys(this.edges).length}`
                                  ] = {
                                    source: audioTrackUID.$.UID,
                                    target: id,
                                  };
                                }
                              );
                              audioTrackUID.audioPackFormatIDRef.forEach(
                                (id) => {
                                  this.edges[
                                    `e${Object.keys(this.edges).length}`
                                  ] = {
                                    source: audioTrackUID.$.UID,
                                    target: id,
                                  };
                                }
                              );
                            }
                          );
                        }
                        if (
                          Object.prototype.hasOwnProperty.call(
                            audioFormatExtended,
                            "audioTrackFormat"
                          )
                        ) {
                          audioFormatExtended.audioTrackFormat.forEach(
                            (audioTrackFormat) => {
                              this.nodes[
                                audioTrackFormat.$.audioTrackFormatID
                              ] = {
                                name: audioTrackFormat.$.audioTrackFormatID,
                                type: audioTrackFormat.$.audioTrackFormatName,
                              };
                              audioTrackFormat.audioStreamFormatIDRef.forEach(
                                (id) => {
                                  this.nodes[id] = { name: id };
                                  this.edges[
                                    `e${Object.keys(this.edges).length}`
                                  ] = {
                                    source:
                                      audioTrackFormat.$.audioTrackFormatID,
                                    target: id,
                                  };
                                }
                              );
                            }
                          );
                        }
                        if (
                          Object.prototype.hasOwnProperty.call(
                            audioFormatExtended,
                            "audioTrackFormat"
                          )
                        ) {
                          audioFormatExtended.audioStreamFormat.forEach(
                            (audioStreamFormat) => {
                              this.nodes[
                                audioStreamFormat.$.audioStreamFormatID
                              ] = {
                                name: audioStreamFormat.$.audioStreamFormatID,
                                type: audioStreamFormat.$.audioStreamFormatName,
                              };
                              audioStreamFormat.audioChannelFormatIDRef.forEach(
                                (id) => {
                                  this.edges[
                                    `e${Object.keys(this.edges).length}`
                                  ] = {
                                    source:
                                      audioStreamFormat.$.audioStreamFormatID,
                                    target: id,
                                  };
                                }
                              );
                            }
                          );
                        }
                        if (
                          Object.prototype.hasOwnProperty.call(
                            audioFormatExtended,
                            "audioChannelFormat"
                          )
                        ) {
                          audioFormatExtended.audioChannelFormat.forEach(
                            (audioChannelFormat) => {
                              this.nodes[
                                audioChannelFormat.$.audioChannelFormatID
                              ] = {
                                name: audioChannelFormat.$.audioChannelFormatID,
                                type: audioChannelFormat.$
                                  .audioChannelFormatName,
                              };
                            }
                          );
                        }
                      }
                    );
                  }
                });
              }
            });
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.parseXML(this.raw).then((data) => {
      if (data == null) return;
      if (Object.prototype.hasOwnProperty.call(data, "ebuCoreMain")) {
        this.data = data;
      }
    });
  },
  methods: {
    reset() {
      this.raw = null;
      this.data = null;
      this.nodes = {};
      this.edges = {};
      this.filename = null;
      this.$refs.fileupload.value = null;
    },
    onChange(event) {
      const reader = new FileReader();
      if (event.target.files[0].name.includes(".xml")) {
        this.filename = event.target.files[0].name.substring(0, 25);
        this.$notify({
          text: "Loading ...",
          type: "info",
          fullWidth: true,
          duration: 300,
        });
        reader.onload = (res) => {
          this.raw = res.target.result;
        };
        reader.onerror = (err) => console.log(err);
        reader.readAsText(event.target.files[0]);
      } else {
        this.$notify({
          text: "File is not a xml.",
          type: "error",
          fullWidth: true,
          duration: 3000,
        });
      }
    },
    parseXML(data) {
      return new Promise((resolve) => {
        let parser = new xml2js.Parser({
          trim: true,
          explicitArray: true,
        });
        parser.parseString(data, function (err, result) {
          resolve(result);
        });
      });
    },
  },
};
</script>
